import { createStore as reduxCreateStore } from "redux";
import window from 'global'

const reducer = (state, action) => {
  
    if (action.type === `TEMPLATE_INFO`) {
        return Object.assign({}, state, {
            templateInfo: action.value,
            templateStructure: state.templateStructure,
            userTemplate: state.userTemplate,
            defaultManufacturer: state.defaultManufacturer,
            defaultCategories: state.defaultCategories,
            getCategoryByUserID: state.getCategoryByUserID,
            templateId: state.templateId,
            defaultUnits: state.defaultUnits,
        });
    }

    if (action.type === `TEMPLATE_STRUCTURE`) {
        return Object.assign({}, state, {
            templateStructure: action.value,
            templateInfo: state.templateInfo,
            userTemplate: state.userTemplate,
            defaultManufacturer: state.defaultManufacturer,
            defaultCategories: state.defaultCategories,
            getCategoryByUserID: state.getCategoryByUserID,
            templateId: state.templateId,
            defaultUnits: state.defaultUnits,
        });
    }

    if (action.type === `MANUFECTURER`) {
        return Object.assign({}, state, {
            defaultManufacturer: action.value,
            templateInfo: state.templateInfo,
            templateStructure: state.templateStructure,
            userTemplate: state.userTemplate,
            defaultCategories: state.defaultCategories,
            getCategoryByUserID: state.getCategoryByUserID,
            templateId: state.templateId,
            defaultUnits: state.defaultUnits,
        });
    }

    if (action.type === `DEFAULT_CATEGORIES`) {
        return Object.assign({}, state, {
            defaultCategories: action.value,
            templateInfo: state.templateInfo,
            defaultManufacturer: state.defaultManufacturer,
            templateStructure: state.templateStructure,
            userTemplate: state.userTemplate,
            getCategoryByUserID: state.getCategoryByUserID,
            templateId: state.templateId,
            defaultUnits: state.defaultUnits,
        });
    }

    if (action.type === `CATEGORIES_BY_USERID`) {
        return Object.assign({}, state, {
            getCategoryByUserID: action.value,
            templateInfo: state.templateInfo,
            defaultManufacturer: state.defaultManufacturer,
            templateStructure: state.templateStructure,
            userTemplate: state.userTemplate,
            defaultCategories: state.defaultCategories,
            templateId: state.templateId,
            defaultUnits: state.defaultUnits,
        });
    }
    if (action.type === `USER_TEMPLATES`) {
        return Object.assign({}, state, {
            userTemplate: action.value,
            templateInfo: state.templateInfo,
            defaultManufacturer: state.defaultManufacturer,
            templateStructure: state.templateStructure,
            defaultCategories: state.defaultCategories,
            getCategoryByUserID: state.getCategoryByUserID,
            templateId: state.templateId,
            defaultUnits: state.defaultUnits,
        });
    }

    if (action.type === 'TEMPLATE_ID') {
        return Object.assign({}, state, {
            templateId: action.value,
            templateInfo: state.templateInfo,
            defaultManufacturer: state.defaultManufacturer,
            templateStructure: state.templateStructure,
            defaultCategories: state.defaultCategories,
            getCategoryByUserID: state.getCategoryByUserID,
            userTemplate: state.userTemplate,
            defaultUnits: state.defaultUnits,
        });
    }

    if (action.type === 'DEFAULT_UNITS') {
        return Object.assign({}, state, {
            defaultUnits: action.value,
            templateId: state.templateId,
            templateInfo: state.templateInfo,
            defaultManufacturer: state.defaultManufacturer,
            templateStructure: state.templateStructure,
            defaultCategories: state.defaultCategories,
            getCategoryByUserID: state.getCategoryByUserID,
            userTemplate: state.userTemplate,
        });
    }

    return state;
};

const initialState = { templateInfo: [], templateStructure: [], defaultManufacturer: [], getCategoryByUserID: [], userTemplate:[], defaultCategories: [], defaultUnits: [], roofPitch: '' , homeLocation:'', serviceNeeded:'', sizeOptions:'', storyOptions:'', qualityLevel:'', templateId: ''};
const createStore = () => reduxCreateStore(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
// const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;