import wrapWithProvider from "./wrap-with-provider";
import styled from 'styled-components';
const ReactDOM = require('react-dom');

export const wrapRootElement = wrapWithProvider;
export function replaceHydrateFunction() {

    return (element, container, callback) => {

        ReactDOM.render(element, container, callback)

    }

}

